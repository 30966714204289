<template>
    <div class="bkColor">
        <headTitle :title="title"></headTitle>
        <van-search v-model="str" input-align="center" placeholder="请输入搜索关键词" shape="round" show-action @search="onSearch"
            @cancel="onCancel" style="margin-top:2vh" />
        <div class="search">
            <div>状态：</div>
            <div style="display:flex; justify-content: space-around;align-items: center;">
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.projectState" :options="stateOption" @change="changePjState" />
                </van-dropdown-menu>
                <div class="searchTitle">年份：</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.year" :options="yearOption" @change="changeYear" />
                </van-dropdown-menu>
                <div class="searchTitle">归属：</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.belong" :options="belongOption" @change="changeBelong" />
                </van-dropdown-menu>
            </div>

        </div>
        <div class="list">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
                    finished-text="没有更多了" @load="getPjList">
                    <div class="listCell" v-for="item in   list  " :key="item.id" @click="goDetails(item.id)">
                        <div class="cellName">{{ item.projectName }}</div>
                        <div class="percentage"
                            :class="{ 'g': item.budgetPercent < 70, 'o': 70 <= item.budgetPercent && item.budgetPercent < 85, 'r': 85 <= item.budgetPercent }">
                            已使用{{ item.budgetPercent || 0 }}%</div>
                        <div class="cellText">项目状态：{{ stateText(item.projectState) }}</div>
                        <div class="cellText">项目类型：{{ item.projectTypeName }}</div>
                        <div class="cellText">创建时间：{{ item.createTime }}</div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
        <div class="addBtn">
            <img src="../assets/icon/xz_icon.png" alt="" @click="addPj">
        </div>
    </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import Vue from 'vue';
import { Search, Toast, List, PullRefresh, Cell } from 'vant';
import { infoList } from '../api/info'
import { pjStateText, yearOption } from '../units/units'

Vue.use(Search);
Vue.use(Toast)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Cell);
export default {
    name: 'pjList',
    components: {
        headTitle
    },
    data() {
        return {
            title: '项目列表',
            str: '',
            stateOption: [
                { text: '全部', value: null },
                { text: '立项审批中', value: 1 },
                { text: '立项完成', value: 2 },
                { text: '签订合同', value: 3 },
                { text: '实施中', value: 4 },
                { text: '实施完成', value: 5 },
                { text: '验收通过', value: 6 },
                { text: '收款完成', value: 7 },
                { text: '付款完成', value: 8 },
                { text: '归档完成', value: 9 },
                { text: '项目完成', value: 10 },
                { text: '项目中止', value: 11 },
                { text: '撤销', value: 12 },
            ],
            yearOption: yearOption,
            belongOption: [
                { text: '全部', value: null },
                { text: '大美', value: 'DM' },
                { text: '中创', value: 'ZC' }
            ],
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
            error: false,
            queryFrom: {
                year: 2023,
                projectState: null,
                projectName: '',
                belong: null,
                pageSize: 10,
                pageNum: 1,
                notStart: null,
                doing: null,
                isOverBudget: null,
                isLate: null,
            },
        }
    },
    mounted() {
        let obj = this.$route.query
        if (Object.keys(obj).length !== 0) {
            let queryKey = Object.keys(this.$route.query.obj)
            for (let key in this.queryFrom) {
                if (key == queryKey[0]) {
                    this.queryFrom[key] = Object.values(this.$route.query.obj)[0]
                }
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log(to, from.path)
        if (from.path == '/newPj') {
            next(vm => {
                vm.onCancel()
            })
        }
        next()
    },
    methods: {
        getPjList() {
            console.log(111)
            this.loading = true
            infoList(this.queryFrom).then(res => {
                this.list = this.queryFrom.pageNum == 1 ? res.data.rows : this.list.concat(res.data.rows)
                this.refreshing = false
                this.loading = false
                this.queryFrom.pageNum = this.queryFrom.pageNum + 1
                if (res.data.total <= (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize) {
                    console.log((this.queryFrom.pageNum - 1) * this.queryFrom.pageSize)
                    this.finished = true
                }
            })
        },
        //搜索框查询
        onSearch(val) {
            this.queryFrom.projectName = val
            this.onRefresh()
            // this.$router.push({ path: '/preview', query: { url: val } })
        },
        //搜索框取消
        onCancel() {
            this.queryFrom.projectName = ''
            this.queryFrom.projectState = null
            this.queryFrom.belong = null
            this.onRefresh()
        },
        //下拉刷新
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.queryFrom.pageNum = 1
            this.list = []
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.getPjList();
        },
        //项目详情
        goDetails(id) {
            console.log(id)
            this.$router.push({ path: '/pjDetails', query: { id: id } })
        },
        //新增项目
        addPj() {
            this.$router.push({ path: '/newPj' })
        },
        //获取项目状态
        stateText(t) {
            return pjStateText(t)
        },

        changePjState(val) {
            this.queryFrom.projectState = val
            this.onRefresh()
        },
        changeYear(val) {
            this.queryFrom.year = val
            this.onRefresh()
        },
        changeBelong(val) {
            this.queryFrom.belong = val
            this.onRefresh()
        }
    },

}
</script>

<style lang="less" scoped>
.g {
    color: #00AB45;
}

.o {
    color: #F0A239;
}

.r {
    color: #E86F61;
}

.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #F6F6F6;
    position: relative;
}

.search {
    width: 100vw;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    background: #fff;
    font-size: 13px;
    line-height: 8vh;
}

.searchTitle {
    margin-left: 4vw;
}

/deep/ .van-ellipsis {
    font-size: 13px;
}

.list {
    margin-top: 2vh;

    /deep/.van-pull-refresh {
        min-height: 71vh;
    }
}

.listCell {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 5vw 1vh;
    background: #fff;
    text-align: left;
    position: relative;
}

.percentage {
    position: absolute;
    right: 12vw;
    font-size: 13px;
    text-align: left;
}

.cellName {
    width: 90%;
    font-size: 1rem;
    font-weight: 600;
    border-top: 2px solid #F6F6F6;
    padding-top: 1vh;
    padding-bottom: 1vh;
}

/deep/ .van-list :first-of-type.listCell .cellName {
    border-top: unset;
}

.cellText {
    width: 70%;
    color: #797979;
    margin-bottom: 0.5vh;
    font-size: 0.7rem;
    word-break: break-all;
    line-height: 1;
}

.addBtn {
    width: 70px;
    height: 70px;
    position: fixed;
    top: 77%;
    left: 77%;

    img {
        width: 70px;
        height: 70px;
    }
}
</style>